import { Box } from "@mui/material";
import "./LogDetails.css";
import { useCallback, useEffect, useState } from "react";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import { columnsDataGridCronDetail } from "../../Utils/Columns/ColumnsDataGridCrons";
import { getAllLogs } from "../../Services/Monitor/MonitorServices";
import moment from "moment";
import { toast } from "react-toastify";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import { LogDetails } from "./LogDetails";

const LogDetailCron = (props: any) => {
  const [rowsForTable, setRowsForTable] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [pageSize] = useState(100);

  const [params, setParams] = useState({
    from_date: moment().startOf("year").format(), // Primer día del año actual
    to_date: moment().format(), // Fecha actual
    endpoints: undefined,
    statusCode: undefined,
    error: undefined,
    page: page,
    size: pageSize,
  });

  const handleNewPage = useCallback((e: any) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: e,
    }));
  }, []);

  useEffect(() => {
    setParams((prevParams: any) => ({
      ...prevParams,
      endpoints: [props.selectedRow.endpoint],
    }));
  }, [props]);

  const onCellClick = (e: any) => {
    if (rowsForTable && rowsForTable.length > 0) {
      const rowSelected = rowsForTable?.find((log: any) => log.id === e.id);
      setSelectedRow(rowSelected);
      setOpenModalShow(true);
    }
  };

  useEffect(() => {
    if (params.endpoints) {
      getAllLogs(params)
        .then((response: any) => {
          setRowCountState(response.data.total);
          setRowsForTable(response.data.items);
        })
        .catch((err: any) => {
          toast.error("Hubo un error en la petición");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params]);

  return (
    <div>
      <Box component="div">
        <h1 className="my-2">{props.selectedRow.endpoint}</h1>
        <div className="card-container">
          <DataGridEditabled
            onlyEdit={false}
            showHeader={false}
            rows={rowsForTable ?? []}
            columns={columnsDataGridCronDetail}
            hideFooterPagination={false}
            rowCount={rowCountState}
            onCellClick={(event: any) => onCellClick(event)}
            rowsPerPageOptions={[50]}
            pagination
            page={params.page}
            pageSize={pageSize}
            loading={loading}
            onPageChange={handleNewPage}
          ></DataGridEditabled>
        </div>
      </Box>
      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => {
          setOpenModalShow(!openModalShow);
        }}
        title="Detalle del Log"
        body={
          <LogDetails
            selectedRow={selectedRow}
            setOpenModalShow={setOpenModalShow}
            show={() => setOpenModalShow(!openModalShow)}
          />
        }
      ></CustomModal>
    </div>
  );
};

export default LogDetailCron;
