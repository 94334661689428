import axios, { AxiosResponse } from "axios";

/**
 *
 * @returns
 */
export const exportGitMonitor = (): any => {
  const userToken = JSON.parse(
    localStorage.getItem("aedas_user") ?? "{}"
  ).token;
  return axios({
    url: `${process.env.REACT_APP_API_BACKEND}/exportGitMonitor`,
    method: "POST", // Cambiamos a método POST
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${userToken}`, // Agregamos el encabezado de autorización
    },
  });
};

/**
 *
 * @returns
 */
export const getProjectsForFilter = () => {
  const userToken = JSON.parse(
    localStorage.getItem("aedas_user") ?? "{}"
  ).token;
  return axios({
    url: `${process.env.REACT_APP_API_BACKEND}/gitProjects`,
    method: "GET", // Cambiamos a método POST
    headers: {
      Authorization: `Bearer ${userToken}`, // Agregamos el encabezado de autorización
    },
  });
};
