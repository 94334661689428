import React, { useEffect, useState } from "react";
import { FaLaptopCode } from "react-icons/fa";

import {
  Route,
  useHistory,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { toast, ToastContainer } from "react-toastify";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./Utils/NavigationClient";
import ViewListIcon from "@mui/icons-material/ViewList";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CodeIcon from "@mui/icons-material/Code";
import GroupsIcon from "@mui/icons-material/Groups";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AedasLoading from "./Components/AedasLoading/AedasLoading";
import Header from "./Components/Header/Header";

import { loginRequest } from "./authConfig";
import { loginAzure } from "./Services/AuthService/AuthService";
import { userLogin } from "./Redux/User/UserAction";

import { LogDetails } from "./Pages/LogDetails/LogDetails";
import { Statistics } from "./Pages/Statistics/Statistics";
import { Home } from "./Pages/Home/Home";
import { Users } from "./Pages/Users/Users";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Git } from "./Pages/Git/Git";
import { Cron } from "./Pages/Cron/Cron";
import { Prinex } from "./Pages/Prinex/Prinex";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && inProgress === "none") {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          const responseBackend: any = await loginAzure(response.accessToken);

          let position: string = "Por defecto";

          if (responseBackend) {
            dispatch(
              userLogin(responseBackend.data, response.accessToken, position)
            );

            localStorage.setItem(
              "aedas_user",
              JSON.stringify(responseBackend.data)
            );

            if (
              location.pathname !== "/usuarios" &&
              location.pathname !== "/git" &&
              location.pathname !== "/estadisticas" &&
              location.pathname !== "/cron"
            ) {
              history.push("/listado");
            }
          } else {
            toast.error("No tienes permiso");
            history.push("/login");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (!isAuthenticated && inProgress === "none") {
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          console.log("login", response);
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (inProgress === "none") {
      setIsLoading(false);
    }
  }, [isAuthenticated, inProgress]);

  const menu: any = [
    {
      name: "Listado de logs",
      localPath: "/listado",
      icon: <ViewListIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Logs",
      hasPermissions: true,
    },
    {
      name: "Estadísticas",
      localPath: "/estadisticas",
      icon: <LeaderboardIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Estad.",
      hasPermissions: true,
    },
    {
      name: "Gestion de usuarios",
      localPath: "/usuarios",
      icon: <GroupsIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Usuarios",
      hasPermissions: true,
    },
    {
      name: "Git",
      localPath: "/git",
      icon: <CodeIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Git",
      hasPermissions: true,
    },
    {
      name: "Prinex",
      localPath: "/prinex",
      icon: <FaLaptopCode size={22} color="black" />,
      onClick: history.push,
      canView: true,
      shortName: "Prinex",
      hasPermissions: true,
    },
    {
      name: "Cron",
      localPath: "/cron",
      icon: <AccessAlarmIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Cron",
      hasPermissions: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div
      style={{ height: "100vh", overflowX: "hidden" }}
      className="app-container"
    >
      {isLogin ? (
        <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
      ) : (
        <></>
      )}

      <div
        className="w-100"
        style={{
          flex: 1,
          marginLeft: `${isLogin ? "70px" : "0"}`,
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Switch>
          <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/listado"
          >
            <Home />
          </PrivateRoute>

          <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/detalle-registro/:id"
          >
            <LogDetails />
          </PrivateRoute>

          <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/estadisticas"
          >
            <Statistics />
          </PrivateRoute>

          <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/usuarios"
          >
            <Users />
          </PrivateRoute>

          <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/git">
            <Git />
          </PrivateRoute>
          <PrivateRoute
            auth={isLogin}
            hasPermissions={true}
            exact
            path="/prinex"
          >
            <Prinex />
          </PrivateRoute>
          <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/cron">
            <Cron />
          </PrivateRoute>
        </Switch>
      </div>
    </div>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <Redirect to="/" />;
  }
};
