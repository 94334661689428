import { useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import { LogDetails } from "../LogDetails/LogDetails";

import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getAllLogs,
  getFilterOptions,
} from "../../Services/Monitor/MonitorServices";
import { MessageResponseGit } from "../../Interfaces/Services";
import { cleanResponseApiLog } from "../../Utils/util";
import {
  exportGitMonitor,
  getProjectsForFilter,
} from "../../Services/Monitor/Git/gitService";
import { toast } from "react-toastify";
import Status from "../../Components/Status/Status";

export const Git = () => {
  const [page] = useState(0);
  const [pageSize] = useState(100);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [rowsForTable, setRowForTable] = useState<any>([]);

  const [servicesParams, setServicesParams] = useState({
    page: page,
    size: pageSize,
    from_date: moment().subtract(3, "days").format(),
    to_date: moment().format(),
    endpoints: ["insertCommits"],
    promocion: [],
    origen: [],
    url: [],
    statusCode: [],
    userName: [],
    service: [],
    uhnreg: [],
    idOpportunity: [],
    error: [], //lista de núm KO -> 1, OK -> 2, OK con error -> 3
    project: undefined,
    branch: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, isFetchingOptions] = useFetch(getFilterOptions, {
    tab: 1,
  });
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rowCountState, setRowCountState] = useState(pageSize);
  const statusOptions = ["OK", "OK Error", "KO"];
  const [projectOptions, setProjectOptions] = useState<String[]>([]);
  const STATES_FILTER_KEY = {
    OK: 1,
    OK_ERROR: 2,
    KO: 500,
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModalShow) {
        setOpenModalShow(false);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [openModalShow]);

  const formik = useFormik({
    initialValues: {
      fromDate: moment().subtract(3, "days").format("YYYY-MM-DD"),
      untilDate: moment().format("YYYY-MM-DD"),
      endpoints: servicesParams.endpoints,
      promocion: servicesParams.promocion,
      url: servicesParams.url,
      origen: servicesParams.origen,
      statusCode: servicesParams.statusCode,
      userName: servicesParams.userName,
      page: servicesParams.page,
      service: servicesParams.service,
      uhnreg: servicesParams.uhnreg,
      idOpportunity: servicesParams.idOpportunity,
      error: servicesParams.error,
      project: servicesParams.project,
      branch: servicesParams.branch,
    },

    onSubmit: async (values: any) => {
      console.log(values);
      const newServices: any[] = [];
      values.service.forEach((service: any) => {
        newServices.push(
          service === "OK"
            ? STATES_FILTER_KEY.OK
            : service === "KO"
            ? STATES_FILTER_KEY.KO
            : STATES_FILTER_KEY.OK_ERROR
        );
      });

      setServicesParams({
        ...values,
        from_date: values.fromDate + "T00:00:00",
        to_date: values.untilDate + "T23:59:59",
        size: 100,
        service: values.service,
        statusCode: newServices,
      });

      updateLogs();
    },
  });

  const [allLogs, isFetchingLogs, updateLogs] = useFetch(
    getAllLogs,
    servicesParams
  );

  useEffect(() => {
    if (allLogs) {
      getProjectsByFilter();
      setRowCountState(allLogs.total);
      const dataLog = [...allLogs?.items];

      // eslint-disable-next-line array-callback-return
      const dataMapped = dataLog.map((mapped) => {
        const message = JSON.parse(mapped.message);
        const responseMessage = message.response[0].message;

        const cleanedMessage = cleanResponseApiLog(responseMessage);

        // Analiza la cadena JSON limpia
        try {
          const decodedMessage: MessageResponseGit = JSON.parse(cleanedMessage);
          // Verifica si el email es "Subida automática"
          const email = decodedMessage.informacion?.autor?.email;
          if (email && email.trim().toLowerCase() === "subida automática") {
            // Si el email es "Subida automática", retorna null o algún valor que indique que debes omitir este elemento en la lista final.
            return null;
          }
          return {
            id: mapped.id,
            nameProject: decodedMessage.informacion.proyecto.nombre
              .replace("AEDAS Homes -", "")
              .trim(),
            email: decodedMessage.informacion?.autor?.email,
            exist_associated_task:
              decodedMessage?.informacion?.autor?.existe_tarea_asociada ?? true,
            normativa_OK:
              decodedMessage?.informacion?.autor?.normativa_OK ?? true,
            estado_correspondiente:
              decodedMessage?.informacion?.autor?.estado_correspondiente,
            comment: decodedMessage?.informacion?.autor?.comentario,
            statusCode: mapped.status_code,
            time: mapped.time,
            status_code: decodedMessage.status_code,
            state_task_jira:
              decodedMessage?.informacion?.autor?.estado_correspondiente ??
              true,
            state_jira: decodedMessage?.informacion?.autor?.estado_tarea,
            branch_git:
              decodedMessage?.informacion?.autor?.rama_afectada ??
              decodedMessage.informacion.repositorio.rama.split("heads/")[1],
          };
        } catch (error) {
          console.error("Error al analizar la cadena JSON:", error);
        }
      });

      //!Uncaught TypeError: rowModel is undefined
      const filteredRows = dataMapped.filter(
        (row) => row !== null && row !== undefined
      );

      setRowForTable(filteredRows);
    }
  }, [allLogs]);

  const getProjectsByFilter = () => {
    getProjectsForFilter().then((resp: any) => {
      if (resp) {
        setProjectOptions(resp.data);
      }
    });
  };

  const isLoading = useIsLoading([isFetchingLogs, isFetchingOptions]);

  const columns: GridColDef[] = [
    {
      field: "exist_associated_task",
      headerName: "Associated task",
      type: "boolean",
      editable: false,
      flex: 0,
    },
    {
      field: "normativa_OK",
      headerName: "Normative",
      type: "boolean",
      editable: false,
      flex: 0,
    },
    {
      field: "state_task_jira",
      headerName: "State Task Jira",
      type: "boolean",
      editable: false,
      flex: 0,
    },
    {
      field: "branch_git",
      headerName: "Branch Git",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div title={params.row.branch_git} className="w-100">
            {params.row.branch_git ?? "-"}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      field: "state_jira",
      headerName: "State Jira",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div title={params.row.state_jira} className="w-100">
            {params.row.state_jira ?? "-"}
          </div>
        );
      },
      editable: false,
      flex: 0,
    },
    {
      field: "statusCode",
      headerName: "Status",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const backgroundColor: any = {
          OK: "var(--aedas-green)",
          KO: "var(--aedas-red)",
          OK_Error: "var(--aedas-yellow)",
        };

        if (params.row.status_code === "OK") {
          return (
            <div
              className="status-button"
              style={{ backgroundColor: `${backgroundColor["OK"]}` }}
            ></div>
          );
        } else {
          if (params.row.status_code === "OK Error") {
            return (
              <div
                className="status-button"
                style={{ backgroundColor: `${backgroundColor["OK_Error"]}` }}
              ></div>
            );
          } else {
            return (
              <div
                className="status-button"
                style={{ backgroundColor: `${backgroundColor["KO"]}` }}
              ></div>
            );
          }
        }
      },
      editable: false,
      flex: 0,
    },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{moment(params.row.time).format("YYYY/MM/DD HH:mm")}</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div title={params.row.email} className="w-100">
            {params.row.email ?? "-"}
          </div>
        );
      },
      editable: false,
      flex: 2,
    },
    {
      field: "nameProject",
      headerName: "Project Name",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div title={params.row.nameProject} className="w-100">
            {params.row.nameProject ?? "-"}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Comment",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div className="w-100" title={params.row.comment ?? "-"}>
            {params.row.comment ?? "-"}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
  ];

  const handleResetFilter = () => {
    formik.resetForm();
    formik.handleSubmit();
  };

  const onCellClick = (e: any) => {
    if (allLogs && allLogs.items.length > 0) {
      setSelectedRow(allLogs.items?.find((log: any) => log.id === e.id));
      setOpenModalShow(true);
    }
  };

  const getExcelGitResult = () => {
    if (isLoadingExcel) {
      return;
    }
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    setIsLoadingExcel(true);

    const loadingToastId = toast.loading("Cargando...");

    exportGitMonitor()
      .then(async (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `reporte_${formattedDate}.xlsx`); // Nombre del archivo con la fecha
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        console.log("error: ", error);
        toast.error("Ha fallado la descarga del excel");
      })
      .finally(() => {
        setIsLoadingExcel(false);
        // Cierra la notificación de carga
        toast.dismiss(loadingToastId);
      });
  };

  const handleNewPage = (e: any) => {
    formik.setFieldValue("page", e);
    formik.handleSubmit();
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <div className="h-100 w-full m-4">
            <div className="main-container p-3" id="home">
              <h4 className="main-title mb-3">Monitor API</h4>
              <Status />
              <Box
                component="form"
                sx={{
                  width: "100%",
                  mx: "auto",
                  my: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
                noValidate
                autoComplete="on"
                className="text-center"
                onSubmit={formik.handleSubmit}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem 0",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      my: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        width: "85%",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="fromDate"
                        label="Date from"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        style={{ margin: "1rem 1rem 1rem 0rem", width: "40%" }}
                        type={"date"}
                        value={formik.values.fromDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.fromDate &&
                          Boolean(formik.errors.fromDate)
                        }
                        helperText={
                          formik.touched.fromDate && formik.errors.fromDate
                        }
                      />
                      <TextField
                        id="untilDate"
                        label="Date until"
                        variant="outlined"
                        style={{ margin: "1rem 0", width: "40%" }}
                        InputLabelProps={{ shrink: true }}
                        type={"date"}
                        value={formik.values.untilDate}
                        inputProps={{
                          min: formik.values.fromDate,
                        }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.untilDate &&
                          Boolean(formik.errors.untilDate)
                        }
                        helperText={
                          formik.touched.untilDate && formik.errors.untilDate
                        }
                      />
                      <Autocomplete
                        id="ko-ok"
                        options={statusOptions}
                        multiple
                        onChange={(e, value) => {
                          formik.setFieldValue("service", value);
                        }}
                        style={{
                          width: "30%",
                          marginRight: "1rem",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "1rem",
                        }}
                        value={formik.values.service}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            error={
                              formik.touched.service &&
                              Boolean(formik.errors.service)
                            }
                            helperText={
                              formik.touched.service && formik.errors.service
                            }
                          />
                        )}
                      />
                      <Autocomplete
                        id="project"
                        options={projectOptions}
                        multiple
                        limitTags={2}
                        onChange={(e, values) => {
                          console.log(values);
                          formik.setFieldValue("origen", values);
                        }}
                        style={{
                          width: "50%",
                          marginRight: "1rem",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: ".1rem",
                        }}
                        defaultValue={formik.values?.project}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Departamento/Proyecto"
                            variant="outlined"
                            error={
                              formik.touched.project &&
                              Boolean(formik.errors.project)
                            }
                            helperText={
                              formik.touched.project && formik.errors.project
                            }
                          />
                        )}
                      />
                      <Autocomplete
                        id="branch"
                        options={["DEV", "PRO", "UAT"]}
                        onChange={(e, value) => {
                          console.log(value);
                          formik.setFieldValue("branch", value);
                        }}
                        style={{
                          width: "30%",
                          marginRight: "1rem",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: ".1rem",
                        }}
                        value={formik.values?.branch}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Branch"
                            variant="outlined"
                            error={
                              formik.touched.branch &&
                              Boolean(formik.errors.branch)
                            }
                            helperText={
                              formik.touched.branch && formik.errors.branch
                            }
                          />
                        )}
                      />
                    </div>
                    <div id="filter-buttons" className="button-container">
                      <Button
                        onClick={getExcelGitResult}
                        color="primary"
                        className="button grey-button "
                      >
                        Descargar
                        <svg
                          className="mx-2"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                        >
                          <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                        </svg>
                      </Button>
                      <Button
                        onClick={handleResetFilter}
                        color="primary"
                        className="button grey-button"
                      >
                        Restablecer
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        className="button blue-button"
                      >
                        Buscar
                      </Button>
                    </div>
                  </Box>
                </div>
              </Box>

              <DataGridEditabled
                onlyEdit={false}
                showHeader={false}
                rows={rowsForTable ?? []}
                columns={columns}
                onCellClick={(event: any) => onCellClick(event)}
                hideFooterPagination={false}
                rowCount={rowCountState}
                rowsPerPageOptions={[50]}
                pagination
                page={formik.values.page}
                pageSize={pageSize}
                onPageChange={handleNewPage}
              ></DataGridEditabled>

              <CustomModal
                size="xl"
                show={openModalShow}
                onChange={() => {
                  setOpenModalShow(!openModalShow);
                }}
                title="Detalle del Log"
                body={
                  <LogDetails
                    selectedRow={selectedRow}
                    setOpenModalShow={setOpenModalShow}
                    show={() => setOpenModalShow(!openModalShow)}
                  />
                }
              ></CustomModal>
            </div>
          </div>
        </>
      )}
    </>
  );
};
