import { useState, useEffect, useCallback } from "react";
import { useFetch, useIsLoading } from "./useFetch";
import { getAllLogsCrons } from "../Services/Monitor/Cron/Cron";
import { getFilterOptions } from "../Services/Monitor/MonitorServices";
import { CronServicesParamsSchema } from "../SchemaValidators/CronSchema";
import { initialValues } from "../Constanst/constantsCrons";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { FilterFormData } from "../Interfaces/InterfacesCron";

export const useCronLogs = () => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FilterFormData>({
    defaultValues: initialValues,
    resolver: zodResolver(CronServicesParamsSchema),
  });
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rowsForTable, setRowForTable] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rowCountState, setRowCountState] = useState(pageSize);
  const [allLogs, isFetchingLogs, updateLogs] = useFetch(
    getAllLogsCrons,
    getValues()
  );

  const onSubmit: SubmitHandler<FilterFormData> = useCallback(
    (data) => {
      const copyLogsFilters = allLogs.filter((item: any) => {
        if (
          data.nameCron &&
          data.nameCron.length > 0 &&
          !data.nameCron.includes(item.endpoint)
        ) {
          return false;
        }

        if (
          data.active.length > 0 &&
          !data.active.includes(item.active ? "Active" : "Inactive") &&
          !data.active.includes("All")
        ) {
          return false;
        }

        return true;
      });
      setRowForTable(copyLogsFilters);
    },
    [updateLogs]
  );

  const [filterOptions, isFetchingOptions] = useFetch(getFilterOptions, {
    tab: 1,
  });
  const [optionsCron, setOptionsCron] = useState<string[]>([]);

  useEffect(() => {
    const optionsFiltered = filterOptions?.endpoints?.filter((option: any) =>
      option.includes("CRON")
    );
    setOptionsCron(optionsFiltered);
  }, [filterOptions]);

  useEffect(() => {
    if (allLogs) {
      setRowCountState(allLogs.length);
      setRowForTable(allLogs);
    }
  }, [allLogs]);

  const isLoading = useIsLoading([isFetchingLogs, isFetchingOptions]);

  const handleResetFilter = () => {
    reset({
      page: 1,
      size: pageSize,
      active: [],
      nameCron: [],
      runningOrStopped: [],
    });
    updateLogs();
  };

  const onCellClick = (e: any) => {
    if (allLogs && allLogs.length > 0) {
      setSelectedRow(
        allLogs.find((log: any) => log.endpoint === e.row.endpoint)
      );

      setOpenModalShow(true);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && openModalShow) {
        setOpenModalShow(false);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [openModalShow]);

  const handleNewPage = useCallback(
    (e: any) => {
      setValue("page", e);
      onSubmit(getValues());
    },
    [onSubmit, getValues]
  );

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    rowsForTable,
    selectedRow,
    rowCountState,
    optionsCron,
    isFetchingOptions,
    isFetchingLogs,
    isLoading,
    errors,
    isSubmitSuccessful,
    register,
    control,
    handleSubmit,
    onSubmit,
    getValues,
    reset,
    handleResetFilter,
    onCellClick,
    handleNewPage,
    openModalShow,
    setOpenModalShow,
  };
};
