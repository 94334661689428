import { z } from "zod";

export const CronServicesParamsSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  active: z.array(z.string()).optional(),
  runningOrStopped: z.array(z.string()).optional(),
  nameCron: z.array(z.string()).optional(),
  projectsDepartments: z.array(z.string()).optional(),
});
