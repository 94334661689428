import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { PRIORITYS, backgroundColor } from "../../Constanst/constantsCrons";
import moment from "moment";
export const columnsDataGridCrons: GridColDef[] = [
  {
    field: "endpoint",
    headerName: "Name",
    headerAlign: "left",
    headerClassName: "text-left",
    type: "string",
    editable: false,
    renderCell(params: GridRenderEditCellParams) {
      return (
        <div className="w-100">
          <span
            style={{ width: "100% !important", textAlign: "left" }}
            title={params.row.endpoint}
          >
            {params.row.endpoint}
          </span>
        </div>
      );
    },
    align: "left",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    editable: false,
    align: "left",
    renderCell(params: GridRenderEditCellParams) {
      return (
        <div className="w-100">
          <span
            style={{ width: "100% !important", textAlign: "left" }}
            title={params.row.description}
          >
            {params.row.description}
          </span>
        </div>
      );
    },
    flex: 1,
  },

  {
    field: "repeat_every",
    headerName: "Frequency",
    type: "string",
    editable: false,
    renderCell(params: GridRenderEditCellParams) {
      return (
        <div className="w-75">
          <span
            style={{ width: "100% !important", textAlign: "left" }}
            title={params.row.repeat_every}
          >
            {params.row.repeat_every}
          </span>
        </div>
      );
    },
    align: "left",
    flex: 1,
  },
  {
    field: "last_execution",
    align: "left",
    headerName: "Last execution",
    type: "string",
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <div
          className="w-75"
          title={moment(params.row.last_execution).format("YYYY/MM/DD HH:mm")}
        >
          {moment(params.row.last_execution).isValid()
            ? moment(params.row.last_execution).format("YYYY/MM/DD HH:mm")
            : "No disponible"}
        </div>
      );
    },
    editable: false,
    flex: 1,
  },
  {
    field: "active",
    headerName: "Active",
    type: "boolean",
    editable: false,
    flex: 0,
  },

  {
    field: "priority",
    headerName: "Priority",
    type: "string",
    align: "left",
    renderCell: (params: GridRenderEditCellParams) => {
      if (params.row.priority === PRIORITYS.HIGHT) {
        return (
          <div
            className="status-button w-100"
            style={{ backgroundColor: `${backgroundColor["KO"]}` }}
          ></div>
        );
      } else {
        return (
          <div
            className="status-button"
            style={{ backgroundColor: `${backgroundColor["OK"]}` }}
          ></div>
        );
      }
    },
    editable: false,
    flex: 0,
  },
];

export const columnsDataGridCronDetail: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "string",
    editable: false,
    flex: 1,
  },
  {
    field: "endpoint",
    headerName: "Name",
    type: "string",
    editable: false,
    flex: 1,
  },

  {
    field: "origin",
    headerName: "Origin",
    type: "string",
    editable: false,
    flex: 0,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <div
          className="w-75"
          title={moment(params.row.time).format("YYYY/MM/DD HH:mm")}
        >
          {moment(params.row.time).isValid()
            ? moment(params.row.time).format("YYYY/MM/DD HH:mm")
            : "No disponible"}
        </div>
      );
    },
    editable: false,
    flex: 1,
  },

  {
    field: "statusCode",
    headerName: "status",
    type: "string",
    renderCell: (params: GridRenderEditCellParams) => {
      if (params.row.statusCode === 1) {
        return (
          <div
            className="status-button"
            style={{ backgroundColor: `${backgroundColor["OK"]}` }}
          ></div>
        );
      } else {
        return (
          <div
            className="status-button"
            style={{ backgroundColor: `${backgroundColor["KO"]}` }}
          ></div>
        );
      }
    },
    editable: false,
    flex: 0,
  },
];
