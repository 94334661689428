import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { TextField, Autocomplete, Select, Button } from "@mui/material";

import AedasLoading from "../../../Components/AedasLoading/AedasLoading";

import {
  addNewUser,
  getAllUsersEmails,
} from "../../../Services/UserService/UserService";

export const NewUserModal = (props: any) => {
  const [selected, setSelected] = useState<any>(null);
  const { setOpenEditModalShow } = props;

  return (
    <>
      <FormNewUserInternal
        setOpenEditModalShow={setOpenEditModalShow}
        setIsLoading={props.setIsLoading}
      ></FormNewUserInternal>
    </>
  );
};

const FormNewUserInternal = (props: any) => {
  const { setOpenEditModalShow } = props;
  const [userEmails, setUserEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: null,

    onSubmit: (values: any) => {
      addNewUser(values.email)
        .then(() => {
          toast.success("Usuario añadido");
          setOpenEditModalShow(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    },
  });

  useEffect(() => {
    getAllUsersEmails()
      .then((response) => response.data)
      .then((data) => {
        setUserEmails(data);
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='p-4'>
        <Autocomplete
          className='w-full'
          disablePortal
          id='email'
          /* A la vez que se guarda la opción escogida en el formik.values, también se guarda en la variable de estado */
          onChange={(event, value) => {
            //@ts-ignore
            formik.setFieldValue("email", value);
          }}
          /* Pinta el array que generamos con un fetch a la base de datos como las diferentes opciones del select */
          options={userEmails}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Email'
              required
              variant='outlined'
              style={{ width: "100%" }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              InputLabelProps={{ shrink: true }}
            />
          )}
        ></Autocomplete>
      </div>
      <div style={{ width: "fit-content", margin: "0 auto" }}>
        <Button type='submit' className='button blue-button'>
          Crear usuario
        </Button>
      </div>
    </form>
  );
};
