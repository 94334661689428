const StatusCron = () => {
  return (
    <>
      <h4 className="text-center">Status</h4>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <span
          className="px-4 rounded text-white"
          style={{ background: "#00b176" }}
        >
          Running/Low
        </span>
        <span
          className="px-4 rounded text-white"
          style={{ background: "#b1003b" }}
        >
          Hight/Stopped
        </span>
      </div>
    </>
  );
};

export default StatusCron;
