import axios, { AxiosResponse } from "axios";
import {
  FilterFormData,
  IResponseLog,
  IResponseLogDetail,
} from "../../../Interfaces/InterfacesCron";
import {
  ENDPOINT_CRONS,
  ENDPOINT_CRON_DETAIL,
} from "../../../Constanst/constantsCrons";
import { getTokenFromStorageAndAuthorize } from "../../../Utils/util";

export const getAllLogsCrons = async (
  params: FilterFormData
): Promise<AxiosResponse<IResponseLog[]>> => {
  const config = getTokenFromStorageAndAuthorize();

  try {
    const url = process.env.REACT_APP_API_BACKEND + ENDPOINT_CRONS;
    const response = await axios.get(url, config);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to fetch cron logs.");
    }
  } catch (error) {
    throw error;
  }
};

export const GetCronDetail = async (): Promise<
  AxiosResponse<IResponseLogDetail>
> => {
  const config = getTokenFromStorageAndAuthorize();

  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BACKEND + `${ENDPOINT_CRON_DETAIL}`,
      config
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to fetch cron detail.");
    }
  } catch (error) {
    throw error;
  }
};

export const getFilterOptions = (params: any) => {
  const config = getTokenFromStorageAndAuthorize();

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/optionsCosmos?tab=" + params.tab,
    config
  );
};
