import { FC, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

export const TimeBarChart: FC<any> = (props: any): any => {
  const daysArray = Array.from({ length: props.monthDays }, (v, i) => i + 1);
  const processedData = daysArray.map((day: number) => {
    let data = {
      Ko: 0,
      Ok_with_errors: 0,
      Ok: 0,
    };

    props.data.forEach((item: any) => {
      if (item.day == day) {
        data.Ko += item.KO;
        data.Ok_with_errors += item.OKWithError;
        data.Ok += item.OK;
      }
    });

    return data;
  });
  //declarar los arrays con los datos por día
  const OKArray = Array.from({ length: 30 }, () => 0);
  const KOArray = Array.from({ length: 30 }, () => 0);
  const OKErrorArray = Array.from({ length: 30 }, () => 0);
  // Recorrer el array 'series'
  props.data.forEach((item: any) => {
    // Obtener el índice del día restando 1 (ya que los días comienzan desde 1)
    const index = item.day - 1;
    // Asignar el valor 'Ok' en el índice correspondiente
    OKArray[index] = item.Ok;
  });
  // Recorrer el array 'series'
  props.data.forEach((item: any) => {
    // Obtener el índice del día restando 1 (ya que los días comienzan desde 1)
    const index = item.day - 1;
    // Asignar el valor 'Ok' en el índice correspondiente
    OKErrorArray[index] = item.Ok_with_errors;
  });
  // Recorrer el array 'series'
  props.data.forEach((item: any) => {
    // Obtener el índice del día restando 1 (ya que los días comienzan desde 1)
    const index = item.day - 1;
    // Asignar el valor 'Ok' en el índice correspondiente
    KOArray[index] = item.Ko;
  });
  const state: any = {
    series: [
      { name: "OK", data: OKArray },
      { name: "OK Error", data: OKErrorArray },
      { name: "KO", data: KOArray },
    ],
    options: {
      colors: ["#00b176", "#87d0ad", "#b1003b"],
      chart: {
        width: 380,
        type: "bar",
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          endingShape: "rounded",
        },
      },

      stroke: {
        width: 1,
        colors: ["transparent"],
      },

      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: daysArray,
      },

      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " requests";
          },
        },
      },

      fill: {
        opacity: 1,
      },

      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div id='chart' style={{ width: "80%", margin: "0 auto" }}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='bar'
      />
    </div>
  );
};
