import axios from "axios";

export const getAllUsers = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };
  return axios.get(process.env.REACT_APP_API_BACKEND + "/user/", config);
};

export const addNewUser = (email: string) => {
  const userToken = JSON.parse(
    localStorage.getItem("aedas_user") ?? "{}"
  ).token;
  return axios({
    url: `${process.env.REACT_APP_API_BACKEND}/user/new?email=${email}`,
    method: "POST", // Cambiamos a método POST
    headers: {
      Authorization: `Bearer ${userToken}`, // Agregamos el encabezado de autorización
    },
  });
};

export const getUserByID = (id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/user/" + id, config);
};

export const removeUser = (id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/user/" + id,
    config
  );
};

export const getAllUsersEmails = () => {
  return axios.get(process.env.REACT_APP_API_BACKEND + "/allEmployeeEmails");
};
