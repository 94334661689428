import moment from "moment";

import { Box } from "@mui/material";
import * as fastXmlParser from "fast-xml-parser";
import "./LogDetails.css";

export const LogDetails = (props: any) => {
  const displayHttpData = (data: string) => {
    let innerString: string = "";

    try {
      const jsonData = JSON.parse(data);
      innerString = JSON.stringify(jsonData, undefined, 3);
    } catch (jsonError) {
      try {
        // Intenta analizar como XML (SOAP)
        const xmlData = parseXml(data);
        innerString = JSON.stringify(xmlData, undefined, 3);
      } catch (xmlError) {
        innerString =
          "No se pudo analizar como ni JSON ni XML:\n\n" +
          data +
          "\n\n" +
          "Error: " +
          xmlError +
          "\n\n" +
          "data: " +
          data;
      }
    }

    return innerString;
  };

  const parseXml = (xmlString: string) => {
    const parser = new fastXmlParser.XMLParser(); // j2xParser for
    return parser.parse(xmlString);
  };

  // Ej

  return (
    <div>
      <Box component="div">
        <div className="card-container">
          <div style={{ display: "flex" }}>
            <div className="notes-box">
              <span className="label">ID</span>
              <p> {props.selectedRow.id} </p>
            </div>

            <div className="notes-box" style={{ flexGrow: "2" }}>
              <span className="label">Endpoint</span>
              <p> {props.selectedRow.endpoint} </p>
            </div>

            <div className="notes-box">
              <span className="label">Endpoint ID</span>
              <p> {props.selectedRow.innerEndpointID ?? "-"} </p>
            </div>

            <div className="notes-box" style={{ width: "30%" }}>
              <span className="label">Fecha</span>
              <p> {moment(props.selectedRow.time).format("DD/MM/YYYY")} </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="notes-box" style={{ flexGrow: "2" }}>
              <span className="label">Origin</span>
              <p> {props.selectedRow.origin} </p>
            </div>

            <div className="notes-box" style={{ width: "35%" }}>
              <span className="label">Status</span>
              <p>
                {" "}
                {props.selectedRow.errorType ??
                  (props.selectedRow.statusCode == 1 ? "OK" : "KO")}{" "}
              </p>
            </div>

            <div className="notes-box" style={{ width: "30%" }}>
              <span className="label">Status Code</span>
              <p> {props.selectedRow.statusCode} </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="notes-box" style={{ width: "35%" }}>
              <span className="label">Uhnreg</span>
              <p> {props.selectedRow.uhnreg ?? "-"} </p>
            </div>

            <div className="notes-box" style={{ width: "35%" }}>
              <span className="label">Uhalia</span>
              <p> {props.selectedRow.uhalia ?? "-"} </p>
            </div>

            <div className="notes-box" style={{ width: "30%" }}>
              <span className="label">Uhnreghijo</span>
              <p> {props.selectedRow.uhnreghijo ?? "-"} </p>
            </div>
          </div>

          <div className="notes-box">
            <span className="label">URL</span>
            <p> {props.selectedRow.url} </p>
          </div>

          <div style={{ display: "flex" }}>
            <div className="notes-box" style={{ flexGrow: "2" }}>
              <span className="label">Usuario</span>
              <p> {props.selectedRow.userName ?? "-"} </p>
            </div>

            <div className="notes-box" style={{ width: "35%" }}>
              <span className="label">ID Promoción</span>
              <p> {props.selectedRow.promotionID ?? "-"} </p>
            </div>

            <div className="notes-box" style={{ width: "30%" }}>
              <span className="label">ID Oportunidad</span>
              <p> {props.selectedRow.idOpportunity ?? "-"} </p>
            </div>
          </div>

          <div className="notes-box">
            <span className="label">Input</span>
            <p>
              {props.selectedRow.input ? (
                <pre> ${displayHttpData(props.selectedRow.input)} </pre>
              ) : (
                "-"
              )}{" "}
            </p>
          </div>

          <div className="notes-box">
            <span className="label">Mensaje</span>
            <p> {props.selectedRow.message} </p>
          </div>
        </div>
      </Box>
    </div>
  );
};
