import axios from "axios";
import { PrinexBody, PrinexInterface } from "../../Interfaces/Prinex";

/**
 * Obtiene todos los registros de Prinex.
 *
 * @param data - Los datos de la solicitud de Prinex.
 * @returns Una promesa que se resuelve en un array de objetos PrinexInterface.
 */
export const getAllPrinex = (
  data: PrinexBody
): Promise<Array<PrinexInterface>> => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  let query = "?fecha=" + data.fecha;

  if (data.origen) query = query + "&origen=" + data.origen;
  if (data.codres) query = query + "&codres=" + data.codres;
  if (data.user) query = query + "&user=" + data.user;
  if (data.promo) query = query + "&promo=" + data.promo;

  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + `/prinex/monitor/${query}`,
      config
    )
    .then((res) => res.data);
};
