import { Autocomplete, Box, Button, TextField } from "@mui/material";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import { Controller } from "react-hook-form";
import { ACTIVE_NO_ACTIVE } from "../../Constanst/constantsCrons";
import { columnsDataGridCrons } from "../../Utils/Columns/ColumnsDataGridCrons";
import { useCronLogs } from "../../Hooks/useCronLogs";
import StatusCron from "../../Components/Status/StatusCrons";
import LogDetailCron from "../LogDetails/LogDetailCron";

export const Cron = () => {
  const {
    page,
    pageSize,
    rowsForTable,
    selectedRow,
    rowCountState,
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    handleResetFilter,
    onCellClick,
    handleNewPage,
    optionsCron,
    openModalShow,
    setOpenModalShow,
  } = useCronLogs();

  return (
    <>
      <>
        <div className="h-100 w-full m-4">
          <div className="main-container p-3" id="home">
            <h4 className="main-title mb-3">Monitor API</h4>
            <StatusCron />
            <Box
              component="form"
              sx={{
                width: "100%",
                mx: "auto",
                my: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
              noValidate
              autoComplete="on"
              className="text-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    my: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex" style={{ width: "85%" }}>
                    <Controller
                      render={({ field }) => (
                        <Autocomplete
                          autoHighlight
                          multiple
                          options={optionsCron ?? []}
                          getOptionLabel={(option) => option}
                          onChange={(_, data) => field.onChange(data)}
                          value={field.value}
                          style={{
                            width: "30%",
                            marginRight: "1rem",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "1rem",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose Cron"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      )}
                      name="nameCron"
                      defaultValue={[]}
                      control={control}
                    />
                    <Controller
                      render={({ field }) => (
                        <Autocomplete
                          autoHighlight
                          multiple
                          options={ACTIVE_NO_ACTIVE}
                          getOptionLabel={(option) => option}
                          onChange={(_, data) => field.onChange(data)}
                          value={field.value}
                          style={{
                            width: "30%",
                            marginRight: "1rem",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "1rem",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Active/inactive"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      )}
                      name="active"
                      defaultValue={[]}
                      control={control}
                    />
                    <div id="filter-buttons" className="d-flex ">
                      <Button
                        onClick={handleResetFilter}
                        color="primary"
                        className="button grey-button"
                      >
                        Restablecer
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        className="button blue-button"
                      >
                        Buscar
                      </Button>
                    </div>
                  </div>
                </Box>
              </div>
            </Box>

            <DataGridEditabled
              onlyEdit={false}
              showHeader={false}
              getRowId={(row: any) => row.endpoint}
              rows={rowsForTable ?? []}
              columns={columnsDataGridCrons}
              onCellClick={(event: any) => onCellClick(event)}
              hideFooterPagination={false}
              rowCount={rowCountState}
              rowsPerPageOptions={[50]}
              pagination
              loading={isLoading}
              page={page}
              pageSize={pageSize}
              onPageChange={handleNewPage}
            ></DataGridEditabled>

            <CustomModal
              size="xl"
              show={openModalShow}
              onChange={() => {
                setOpenModalShow(!openModalShow);
              }}
              title="Detalle del Log"
              body={
                <LogDetailCron
                  selectedRow={selectedRow}
                  setOpenModalShow={setOpenModalShow}
                  show={() => setOpenModalShow(!openModalShow)}
                />
              }
            ></CustomModal>
          </div>
        </div>
      </>
    </>
  );
};
