import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import moment from "moment";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import Status from "../../Components/Status/Status";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import { getAllPrinex } from "../../Services/Prinex/PrinexService";
import { PrinexBody, PrinexInterface } from "../../Interfaces/Prinex";
import { LogDetailPrinex } from "../LogDetails/LogDetailPrinex";

export const Prinex = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<Array<PrinexInterface>>([]);
  const [selectedRow, setSelectedRow] = useState<PrinexInterface | undefined>();
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      date: moment.utc().format("YYYY-MM-DD"),
      origen: null,
      codres: null,
      user: null,
      promo: null,
      page: null,
    },

    onSubmit: async (values) => {
      console.log(values);
      const body: PrinexBody = {
        fecha:
          values.date !== ""
            ? moment(values.date, "YYYY-MM-DD").format("DD-MM-YYYY")
            : moment.utc().format("DD-MM-YYYY"),
        origen:
          values.origen !== null || values.origen !== ""
            ? values.origen
            : undefined,
        codres:
          values.codres !== null || values.codres !== ""
            ? values.codres
            : undefined,
        user:
          values.user !== null || values.user !== "" ? values.user : undefined,
        promo:
          values.promo !== null || values.promo !== ""
            ? values.promo
            : undefined,
      };
      setIsLoading(true);
      getAllPrinex(body)
        .then((response) => {
          setRows(response);
        })
        .finally(() => setIsLoading(false));
    },
  });

  useEffect(() => {
    setIsLoading(true);
    getAllPrinex({ fecha: moment.utc().format("DD-MM-YYYY") })
      .then((response) => {
        setRows(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const columns: GridColDef[] = [
    {
      field: "endpoint",
      headerName: "Endpoint",
      type: "string",
      editable: false,
      flex: 0,
    },
    {
      field: "time",
      headerName: "Tiempo",
      type: "string",
      editable: false,
      flex: 0,
    },
    {
      field: "origin",
      headerName: "Origen",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const backgroundColor = {
          OK: "var(--aedas-green)",
          KO: "var(--aedas-red)",
        };

        if (params.row.status === "OK") {
          return (
            <div
              className='status-button'
              style={{ backgroundColor: `${backgroundColor["OK"]}` }}
            ></div>
          );
        } else if (params.row.status === "KO") {
          return (
            <div
              className='status-button'
              style={{ backgroundColor: `${backgroundColor["KO"]}` }}
            ></div>
          );
        }
      },
      editable: false,
      flex: 0,
    },
    {
      field: "statusCode",
      headerName: "Código Estado",
      type: "string",
      editable: false,
      flex: 0,
    },
    {
      field: "uhnreg",
      headerName: "UHNREG",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Nombre Usuario",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "promotionID",
      headerName: "Id Promoción",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "input",
      headerName: "Campo",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "message",
      headerName: "Mensaje",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "errorType",
      headerName: "Tipo Error",
      type: "string",
      editable: false,
      flex: 1,
    },
  ];

  const resetForm = () => {
    formik.setValues({
      date: moment.utc().format("YYYY-MM-DD"),
      origen: null,
      codres: null,
      user: null,
      promo: null,
      page: null,
    });
  };

  const onCellClick = (e: any) => {
    if (rows && rows.length > 0) {
      const rowSelected = rows?.find((row) => row.id === e.id);
      setSelectedRow(rowSelected);
      setOpenModalShow(true);
    }
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <div className='h-100 w-full m-4'>
          <div className='main-container p-3' id='home'>
            <h4 className='main-title mb-3'>Monitor API</h4>
            <Status />
            <Box
              component='form'
              sx={{
                width: "100%",
                mx: "auto",
                my: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
              noValidate
              autoComplete='on'
              className='text-center'
              onSubmit={formik.handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    my: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className='d-flex'
                    style={{
                      width: "85%",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id='date'
                      label='Fecha'
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      style={{ margin: "1rem 1rem 1rem 0rem", width: "40%" }}
                      type='date'
                      defaultValue={moment.utc().format("YYYY-MM-DD")}
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                    />
                    <TextField
                      id='origen'
                      label='Origen'
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      style={{ margin: "1rem 1rem 1rem 0rem", width: "40%" }}
                      type='text'
                      value={formik.values.origen}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.origen && Boolean(formik.errors.origen)
                      }
                      helperText={formik.touched.origen && formik.errors.origen}
                    />
                    <Autocomplete
                      id='codres'
                      options={["OK", "KO"]}
                      onChange={(e, value) => {
                        formik.setFieldValue("codres", value);
                      }}
                      style={{
                        width: "30%",
                        marginRight: "1rem",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "1rem",
                      }}
                      value={formik.values.codres}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Estado'
                          variant='outlined'
                          error={
                            formik.touched.codres &&
                            Boolean(formik.errors.codres)
                          }
                          helperText={
                            formik.touched.codres && formik.errors.codres
                          }
                        />
                      )}
                    />
                    <TextField
                      id='user'
                      label='Usuario'
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      style={{ margin: "1rem 1rem 1rem 0rem", width: "40%" }}
                      type='text'
                      value={formik.values.user}
                      onChange={formik.handleChange}
                      error={formik.touched.user && Boolean(formik.errors.user)}
                      helperText={formik.touched.user && formik.errors.user}
                    />
                    <TextField
                      id='promo'
                      label='Promoción'
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      style={{ margin: "1rem 1rem 1rem 0rem", width: "40%" }}
                      type='text'
                      value={formik.values.promo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.promo && Boolean(formik.errors.promo)
                      }
                      helperText={formik.touched.promo && formik.errors.promo}
                    />
                  </div>
                  <div id='filter-buttons' className='button-container'>
                    <Button
                      onClick={() => resetForm()}
                      color='primary'
                      className='button grey-button'
                    >
                      Restablecer
                    </Button>
                    <Button
                      type='submit'
                      color='primary'
                      className='button blue-button'
                    >
                      Buscar
                    </Button>
                  </div>
                </Box>
              </div>
            </Box>

            <DataGridEditabled
              onlyEdit={false}
              showHeader={false}
              rows={rows}
              columns={columns}
              onCellClick={(event: any) => onCellClick(event)}
              hideFooterPagination={false}
              rowsPerPageOptions={[50]}
              pagination
              page={formik.values.page}
            ></DataGridEditabled>

            <CustomModal
              size='xl'
              show={openModalShow}
              onChange={() => setOpenModalShow(!openModalShow)}
              title='Detalle del Log'
              body={<LogDetailPrinex selectedRow={selectedRow} />}
            ></CustomModal>
          </div>
        </div>
      )}
    </>
  );
};
