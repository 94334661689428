import { Box } from "@mui/material";
import "./LogDetails.css";
import { PrinexInterface } from "../../Interfaces/Prinex";

interface LogDetailPrinexProps {
  selectedRow: PrinexInterface | undefined;
}

export const LogDetailPrinex = ({ selectedRow }: LogDetailPrinexProps) => {
  function formatJson(input: string) {
    try {
      // Primero intenta convertir el input a un objeto JSON si es una cadena
      const json = typeof input === "string" ? JSON.parse(input) : input;
      // Luego usa JSON.stringify para convertirlo de nuevo en una cadena formateada
      return JSON.stringify(json, null, 2); // `2` es el número de espacios para la indentación
    } catch (e) {
      // Si no es un JSON válido, hacer un reemplazo para agregar saltos de línea después de cada coma
      return input
        .replace(/,/g, ",\n") // Reemplazar comas
        .replace(/{/g, "{\n") // Reemplazar llaves abiertas
        .replace(/}/g, "\n}") // Reemplazar llaves cerradas
        .replace(/\[/g, "[\n") // Reemplazar corchetes abiertos
        .replace(/]/g, "\n]"); // Reemplazar corchetes cerrados
    }
  }

  return (
    <div>
      <Box component='div'>
        <div className='card-container'>
          <div style={{ display: "flex" }}>
            <div className='notes-box'>
              <span className='label'>ID</span>
              <p> {selectedRow?.id ?? "-"} </p>
            </div>

            <div className='notes-box' style={{ flexGrow: "2" }}>
              <span className='label'>Endpoint</span>
              <p> {selectedRow?.endpoint ?? "-"} </p>
            </div>
            {console.log(selectedRow?.time)}
            <div className='notes-box' style={{ width: "30%" }}>
              <span className='label'>Fecha</span>
              <p> {selectedRow?.time ?? "-"} </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className='notes-box' style={{ flexGrow: "2" }}>
              <span className='label'>Origin</span>
              <p> {selectedRow?.origin ?? "-"} </p>
            </div>

            <div className='notes-box' style={{ width: "35%" }}>
              <span className='label'>Status</span>
              <p>
                {" "}
                {selectedRow?.errorType ??
                  (selectedRow?.statusCode == 1 ? "OK" : "KO")}{" "}
              </p>
            </div>

            <div className='notes-box' style={{ width: "30%" }}>
              <span className='label'>Status Code</span>
              <p> {selectedRow?.statusCode} </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className='notes-box' style={{ width: "35%" }}>
              <span className='label'>Uhnreg</span>
              <p> {selectedRow?.uhnreg ?? "-"} </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className='notes-box' style={{ flexGrow: "2" }}>
              <span className='label'>Usuario</span>
              <p> {selectedRow?.userName ?? "-"} </p>
            </div>

            <div className='notes-box' style={{ width: "35%" }}>
              <span className='label'>ID Promoción</span>
              <p> {selectedRow?.promotionID ?? "-"} </p>
            </div>
          </div>

          <div className='notes-box'>
            <span className='label'>Input</span>
            <p>
              {selectedRow?.input ? (
                <pre> ${formatJson(selectedRow?.input)} </pre>
              ) : (
                "-"
              )}{" "}
            </p>
          </div>

          <div className='notes-box'>
            <span className='label'>Mensaje</span>
            <p> {selectedRow?.message ?? "-"} </p>
          </div>
        </div>
      </Box>
    </div>
  );
};
