const Status = () => {
  return (
    <>
      <h4 className="text-center">Status</h4>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <span
          className="px-4 rounded text-white"
          style={{ background: "#ffcc43" }}
        >
          Ok Error
        </span>
        <span
          className="px-4 rounded text-white"
          style={{ background: "#00b176" }}
        >
          OK
        </span>
        <span
          className="px-4 rounded text-white"
          style={{ background: "#b1003b" }}
        >
          KO
        </span>
      </div>
    </>
  );
};

export default Status;
