export const PRIORITYS = {
  HIGHT: "Hight",
  LOW: "Low",
};

export const ACTIVE_NO_ACTIVE = ["Active", "Inactive", "All"];
export const EXECUTION_FREQUENCY = {
  DAILY: { VALUE: 1, TEXT: "Daily" },
  WEEKLY: { VALUE: 2, TEXT: "Weekly" },
  MONTHLY: { VALUE: 3, TEXT: "Monthly" },
};
export const STATE_PROCESS_CRON = ["Running", "Stopped"];

export const backgroundColor: any = {
  OK: "var(--aedas-green)",
  KO: "var(--aedas-red)",
  OK_Error: "var(--aedas-yellow)",
};

export const ENDPOINT_CRONS: string = "/cronInfo";
export const ENDPOINT_CRON_DETAIL: string = "/cosmos/all?page=";

export const initialValues = {
  page: 1,
  size: 100,
  active: [],
  runningOrStopped: [],
  projectsDepartments: [],
  nameCron: [],
};
